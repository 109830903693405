import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { notification, Modal } from "antd"
import React, { useState, useEffect } from "react"
import Seo from "../components/seo"
import NewLayout from "../components/new_layout"
import CardPost from "../components/v2023/UI/Card/card-post"
import Certifications from "../components/v2023/Certifications/certifications"
import Identity from "../components/v2023/Identity/identity"
import CardContactForm from "../components/v2023/UI/Card/card-contact-form"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import { initializeHubspotForm } from "../utils/gatsby-helpers"


const Contact = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page.edges[0].node
    const principals = data.principals.edges
    const commercials = data.commercials.edges
    const showWhatsappWarning = !!page.whatsapp_warning_title && !!page.whatsapp_warning_description
    const [isLinkModalVisible, setIsLinkModalVisible] = useState(false)
    const showLinkModal = () => setIsLinkModalVisible(true)
    const handleLinkModalCancel = () => setIsLinkModalVisible(false)

    const copyToClipboard = () => {
        if (typeof navigator === "undefined" || typeof navigator.clipboard === "undefined") {
            return
        }

        navigator.clipboard.writeText("https://www.tecalis.com/")
            .then(() => notification["success"]({ message: "Copiado al portapapeles!" })) //ToDo: reemplazar por un alert customizado
    }

    const linkForm = {
        "en": {
            region: "na1",
            portal_id: "20249583",
            form_id: "28baa13e-19b8-4de4-9337-fb1e336463f2"
        },
        "es": {
            region: "na1",
            portal_id: "20249583",
            form_id: "5afa9b4b-c30f-4064-bf78-21b76e46c95c"
        }
    }

    const elements = {
        a: ({ node, ...props }) => {
            const { children, href, className, ...rest } = props;

            if (className && className.includes('modal-contacto')) {
                return <a href={href} className={className} onClick={showLinkModal} {...rest}>
                    {children}
                </a>
            }

            return <a href={href} className={className} {...rest}>
                {children}
            </a>
        },
    }

    useEffect(() => {
        if (isLinkModalVisible) {
            initializeHubspotForm(linkForm[lang].portal_id, linkForm[lang].form_id, `#form-link-${linkForm[lang].form_id}`, linkForm[lang].region);
        }
    }, [isLinkModalVisible/*, linkForm*/]);

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />
            <main className="main">
                <div className="main__section main__section--100 color-section purple">
                    <div className="container">
                        <h1 className="merriweather">{page.title}</h1>
                        <div className="grid-lg-3-h">
                            {page.forms.map((form, index) => {
                                return <CardContactForm
                                    key={index}
                                    name={form.title}
                                    image={form.icon}
                                    description={form.description}
                                    button={form.button}
                                    form={form.form}
                                />
                            })}
                        </div>
                        {showWhatsappWarning && (
                            <div className="alert-purple">
                                <i class="icon-whatsapp"></i>
                                <div className="alert-purple__text">
                                    <b>{page.whatsapp_warning_title}</b>
                                    <p>
                                        <ReactMarkdown children={page.whatsapp_warning_description} rehypePlugins={[rehypeRaw]} components={elements} />
                                    </p>
                                </div>
                                <Modal width={"100%"} visible={isLinkModalVisible} onCancel={handleLinkModalCancel} footer={null}>
                                    <div id={`form-link-${linkForm[lang].form_id}`} className="form-hb"/>
                                </Modal>
                            </div>
                        )}
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.headquarters_title}</h2>
                        <div className="grid-lg-3-h">
                            {principals.map((office, index) => {
                                return <CardPost
                                    key={index}
                                    lang={lang}
                                    name={office.node.name}
                                    image={office.node.new_image}
                                    alternativeText={office.node.new_image.alternativeText}
                                    office={true}
                                    description={`<p>${office.node.address}</p> <p>${office.node.city}</p>`}
                                    url={office.node.map_link}
                                    blank={true}
                                />
                            })}
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.commercial_offices_title}</h2>
                        <div className="grid-lg-3-h">
                            {commercials.map((office, index) => {
                                return <div className="image-group" key={index}>
                                    <GatsbyImage alt={office.node.name} image={getImage(office.node.new_image.localFile)} className="" />
                                    <div className="image-group__text">
                                        <h5>{office.node.name}</h5>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                <div className="main__section">
                    <div className="container">
                        <div className="tile tile--gray">
                            <h3>{page.social_media_title}</h3>
                            <div className="tile__body">
                                <div className="button__wrapper">
                                    <a href="https://twitter.com/tecalis" className="button-icon button-icon--secondary button-icon--lg" target="_blank" rel="noreferrer">
                                        <i className="icon-twitter"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/company/tecalis" className="button-icon button-icon--secondary button-icon--lg" target="_blank" rel="noreferrer">
                                        <i className="icon-linkedin"></i>
                                    </a>
                                    <a href="https://www.youtube.com/tecalis" className="button-icon button-icon--secondary button-icon--lg" target="_blank" rel="noreferrer">
                                        <i className="icon-youtube"></i>
                                    </a>
                                    <a href="https://www.facebook.com/tecalis" className="button-icon button-icon--secondary button-icon--lg" target="_blank" rel="noreferrer">
                                        <i className="icon-facebook"></i>
                                    </a>
                                    <a href="https://www.instagram.com/tecalis" className="button-icon button-icon--secondary button-icon--lg" target="_blank" rel="noreferrer">
                                        <i className="icon-instagram"></i>
                                    </a>
                                    <a href="https://pin.it/50JPe8Q" className="button-icon button-icon--secondary button-icon--lg" target="_blank" rel="noreferrer">
                                        <i className="icon-pinterest-full"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Certifications />

                <Identity lang={lang} />
            </main>
        </NewLayout>
    )
}

export default Contact

export const contactQuery = graphql`
    query ($langKey: String) {
        page: allStrapi2023Contacto ( filter: { locale: { eq: $langKey } } ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image_2 {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    forms {
                        icon {
                            name
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                        title
                        description
                        button
                        form {
                            region
                            portal_id
                            form_id
                        }
                    }
                    headquarters_title
                    commercial_offices_title
                    social_media_title
                    whatsapp_warning_title
                    whatsapp_warning_description
                }
            }
        }
        principals: allStrapiOffices ( filter: { locale: { eq: "es" }, type: { eq: "sede_principal" } } ) {
            edges {
                node {
                    name
                    address
                    postal_code
                    city
                    country
                    description
                    map_link
                    new_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
        commercials: allStrapiOffices ( filter: { locale: { eq: "es" }, type: { eq: "oficina_comercial" } } ) {
            edges {
                node {
                    name
                    new_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 650
                                    height: 359

                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
